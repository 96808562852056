import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useUser } from 'reactfire';
import { createContext, useContext } from 'react';
import SparkMD5 from 'spark-md5';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

const CountryCode = {
  SWEDEN: "SE",
  DENMARK: "DK"
};
const CountryName = {
  SWEDEN: "Sweden",
  DENMARK: "Denmark"
};

class Address {
  constructor(data) {
    _defineProperty(this, "data", void 0);

    this.data = data;
  }

  toJSON() {
    return this.data;
  }

  toString() {
    return `${this.streetAddress} - ${this.data.city}`;
  }

  toLatLng() {
    return this.data.coordinates;
  }

  get streetAddress() {
    return `${this.data.street} ${this.data.locator}`;
  }

  get city() {
    return this.data.city;
  }

  get street() {
    return this.data.street;
  }

  get lat() {
    return this.data.coordinates.lat;
  }

  get lng() {
    return this.data.coordinates.lng;
  }

}

const formatInt = input => new Intl.NumberFormat("sv-SE", {
  maximumFractionDigits: 0
}).format(input);

const formatDec = input => new Intl.NumberFormat("sv-SE", {
  maximumFractionDigits: 2
}).format(input);

const isToday = date => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

const formatDate = date => new Intl.DateTimeFormat("sv-SE", {
  day: "numeric",
  month: "long",
  year: "numeric",
  timeZone: "Europe/Stockholm"
}).format(date);

const formatTime = date => new Intl.DateTimeFormat("sv-SE", {
  hour: "2-digit",
  minute: "2-digit",
  timeZone: "Europe/Stockholm"
}).format(date);

const URLSearchParamsFromObj = obj => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    if (value === false || value === undefined) {
      continue;
    }

    if (Array.isArray(value)) {
      for (const subValue of value) {
        params.append(key, subValue.toString());
      }
    } else {
      params.set(key, value.toString());
    }
  }

  return params;
};

const SUGGESTION_STAGE = {
  api_value: "pristine",
  title: "Förslag"
};
const SHOWING_STAGE = {
  api_value: "showing",
  title: "Visning"
};
const NEGOTIATION_STAGE = {
  api_value: "negotiation",
  title: "Förhandling"
};
const SIGNED_STAGE = {
  api_value: "signed",
  title: "Signerat avtal"
};
const STAGES = [SUGGESTION_STAGE, SHOWING_STAGE, NEGOTIATION_STAGE, SIGNED_STAGE];

class Offer {
  constructor(data) {
    _defineProperty(this, "data", void 0);

    this.data = data;
  }
  /**
   * Getters for offer related fields
   */


  get documents() {
    return this.data.files;
  }

  get monthlyRent() {
    return this.data.monthly_rent;
  }

  get terms() {
    return this.data.terms;
  }

  get type() {
    return this.data.type;
  }

  displayRent() {
    if (!this.monthlyRent) {
      return "";
    } else {
      return formatInt(this.monthlyRent) + " kr/mån";
    }
  }

}

class Proposition {
  constructor(data) {
    _defineProperty(this, "currentDate", void 0);

    _defineProperty(this, "data", void 0);

    _defineProperty(this, "created", void 0);

    _defineProperty(this, "stage", void 0);

    _defineProperty(this, "offer", void 0);

    _defineProperty(this, "address", void 0);

    this.data = data;
    this.created = new Date(data.created);
    this.currentDate = new Date();
    this.stage = STAGES.find(s => s.api_value === data.status); // STAGES should be exhaustive for this cast to be valid

    this.address = new Address(data.address);
    this.offer = new Offer(data.offer);
  }

  toJSON() {
    return this.data;
  }

  get id() {
    return this.data.id;
  }

  get shortlist() {
    return this.data.shortlist;
  }

  get listing() {
    return this.data.listing;
  }

  get contact() {
    return this.data.contact;
  }

  get curated() {
    return this.data.curated;
  }

  get priority() {
    return this.data.priority;
  }

  get comment() {
    return this.data.comment;
  }

  get info() {
    return this.data.info;
  }

  get nextAction() {
    return this.data.next_action;
  }
  /**
   * @deprecated Use nextAction instead
   */


  get next_action() {
    return this.nextAction;
  }

  get nextShowing() {
    return this.data.next_showing;
  }
  /**
   * @deprecated Use offer.documents instead
   */


  get files() {
    return this.offer.documents;
  }
  /**
   * Getters for listing related fields
   */


  get documents() {
    return this.data.documents;
  }

  get images() {
    return this.data.images;
  }

  get floorplans() {
    return this.data.floorplans;
  }

  get description() {
    return this.data.description;
  }

  get originalMonthlyRent() {
    return this.data.original_monthly_rent;
  }

  get size() {
    return this.data.size;
  }

  get workspaces() {
    return this.data.workspaces;
  }

  get floor() {
    return this.data.floor;
  }

  get availabilityDate() {
    return this.data.availability_date;
  }

  get isServicedOffice() {
    return this.data.is_serviced_office;
  }

  get isExpired() {
    return this.data.expired !== null && new Date(this.data.expired) < this.currentDate;
  }
  /**
   * Formatting functions for the listing fields
   */

  /**
   * @deprecated since version 2.3.0. Use displayAvailability instead.
   */


  displayAvailabilityDetails() {
    return this.data.availability_details;
  }

  displayAvailability() {
    return this.data.availability_details || this.data.availability_date || "";
  }

  displayOriginalRent() {
    if (!this.originalMonthlyRent) {
      return "";
    } else {
      return formatInt(this.originalMonthlyRent) + " kr/mån";
    }
  }

  displayRent() {
    return this.offer.displayRent() || this.displayOriginalRent();
  }

  displaySize() {
    if (!this.data.size) {
      return "";
    }

    return `${this.data.size} m²`;
  }

  displayWorkspaces() {
    if (!this.data.workspaces) {
      return "";
    } else {
      return `${this.data.workspaces} arbetsplatser`;
    }
  }

  displayNextShowing() {
    if (!this.data.next_showing) return "";
    const startDate = new Date(this.data.next_showing.start);
    const endDate = new Date(this.data.next_showing.end);
    return `${isToday(startDate) ? "Idag" : formatDate(startDate)}, kl.${formatTime(startDate)} - ${formatTime(endDate)}
  `;
  }

}

function roomsToPropositionPayload(shortlist, provider, location) {
  let available = true;
  let latestAvailabilityDate = null;

  for (var _len = arguments.length, rooms = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    rooms[_key - 3] = arguments[_key];
  }

  for (const room of rooms) {
    if (!latestAvailabilityDate || room.availability && room.availability > latestAvailabilityDate) {
      latestAvailabilityDate = room.availability;
    }

    if (!room.vacant) {
      available = false;
    }
  }

  let availability_date = null,
      availability_details = "";

  if (available && latestAvailabilityDate) {
    availability_date = latestAvailabilityDate;
  } else if (available) {
    availability_details = "Tillgänglig";
  } else {
    availability_details = "Ej tillgänglig";
  }

  return {
    offer: {
      type: "suggestion",
      monthly_rent: null,
      terms: "",
      files: []
    },
    title: location.name,
    next_showing: null,
    listing: null,
    expired: null,
    rooms: rooms.map(r => r.id),
    shortlist,
    contact: null,
    priority: 0,
    comment: location.default_pitch,
    curated: false,
    info: "",
    next_action: "",
    size: rooms.reduce((sum, room) => sum + room.size, 0),
    workspaces: rooms.reduce((sum, room) => sum + room.max_workspaces, 0),
    original_monthly_rent: rooms.reduce((sum, room) => sum + room.price, 0),
    documents: location.documents.concat(provider.documents),
    images: [].concat(...rooms.map(r => r.images), location.images),
    floorplans: [].concat(...rooms.map(r => r.floorplans), location.floorplans),
    floor: "",
    // We could maybe get that but it would be very complicated here
    is_serviced_office: true,
    availability_details,
    availability_date,
    description: buildRoomsDescription(provider, location, ...rooms),
    address: location.address,
    location: location.id
  };
}

function buildRoomsDescription(provider, location) {
  let description = "";

  for (var _len2 = arguments.length, rooms = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    rooms[_key2 - 2] = arguments[_key2];
  }

  for (const room of rooms) {
    description += `# Rum

${room.description}

`;
  }

  description += `# ${new Address(location.address).toString()}

${location.description}

# ${provider.name}

${provider.description}`;
  return description;
}

class Shortlist {
  constructor(data) {
    _defineProperty(this, "data", void 0);

    _defineProperty(this, "propositions", void 0);

    _defineProperty(this, "stage", void 0);

    this.data = data;
    this.propositions = data.propositions.map(p => new Proposition(p)).sort((p1, p2) => {
      // Put the curated propositions first
      if (p1.curated !== p2.curated) {
        return p1.curated ? -1 : 1;
      } // Otherwise order by priority


      if (p1.priority !== p2.priority) {
        return p2.priority - p1.priority;
      } // otherwise order by recency


      return p2.created.valueOf() - p1.created.valueOf();
    }); // Calculate the current stage of the shortlist by taking the highest stage among propositions

    let stageIndex = -1,
        stage = STAGES[0];

    for (let p of this.propositions) {
      const index = STAGES.indexOf(p.stage);

      if (index > stageIndex) {
        stage = p.stage;
      }
    }

    this.stage = stage;
  }

  toJSON() {
    return this.data;
  }

  get id() {
    return this.data.id;
  }

  get url() {
    return `/shortlist/${this.data.url}`;
  }

  get name() {
    return this.data.name;
  }

  get owner() {
    return this.data.owner;
  }

}

/**
 * This error will be thrown if we received an answer but the status is not acceptable
 * Note that in case of network error or other unexpected issue other errors might bee thrown
 */
class StatusError extends Error {
  constructor(res, body) {
    super();

    _defineProperty(this, "statusCode", void 0);

    _defineProperty(this, "res", void 0);

    _defineProperty(this, "responseText", void 0);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StatusError);
    }

    this.message = `Incorrect statusCode: ${res.status}`;
    this.statusCode = res.status;
    this.res = res;
    this.responseText = body;
  }

  get responseJson() {
    return JSON.parse(this.responseText);
  }

}

const defaultClientOptions = {
  staging: false,
  apiKey: ""
};

class YtaApiClient {
  constructor(options) {
    _defineProperty(this, "opts", void 0);

    this.opts = { ...defaultClientOptions,
      ...options
    };
  }

  get origin() {
    return this.opts.staging ? "https://staging-api.yta.se" : "https://api.yta.se";
  }

  async request(path, method, options) {
    const url = new URL(path, this.origin);
    const searchParams = options.searchParams || new URLSearchParams();

    for (const [key, value] of searchParams) {
      url.searchParams.append(key, value);
    }

    const headers = options.headers || new Headers(); // Authenticate all the requests if we have an JWT or API key.

    if (options.jwt || this.opts.apiKey) {
      headers.set("Authorization", `Bearer ${options.jwt || this.opts.apiKey}`);
    }

    let body = null;
    if ("body" in options) body = options.body;

    if (!body && "data" in options) {
      body = JSON.stringify(options.data);
      headers.set("Content-Type", "application/json");
    }

    const validStatuses = new Set(options.validStatuses || [200, 201, 202, 204]);
    const response = await fetch(url.toString(), {
      method,
      headers,
      body
    });

    if (!validStatuses.has(response.status)) {
      const responseBody = await response.text();
      throw new StatusError(response, responseBody);
    }

    return response;
  }

  async get(path) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const resp = await this.request(path, "GET", options);
    return await resp.json();
  }

  async post(path, options) {
    const resp = await this.request(path, "POST", options);
    return await resp.json();
  }

  async put(path, options) {
    const resp = await this.request(path, "PUT", options);
    return await resp.json();
  }

  async patch(path, options) {
    const resp = await this.request(path, "PATCH", options);
    return await resp.json();
  }

  async delete(path) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    await this.request(path, "DELETE", options);
  }
  /**
   * IMPLEMENTATION OF THE API ROUTES FOR THE APPLICATION BELOW
   */


  async getServicedOfficeServices() {
    return await this.get("serviced_offices/services");
  }

  async createServicedOfficeService(data) {
    return await this.post("serviced_offices/services", {
      data
    });
  }

  async getServicedOfficeServiceById(id) {
    return await this.get("serviced_offices/services/" + id);
  }

  async updateServicedOfficeService(id, data) {
    return await this.put("serviced_offices/services/" + id, {
      data
    });
  }

  async getServicedOfficeContacts() {
    let criteria = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const searchParams = new URLSearchParams();

    if (criteria.provider) {
      searchParams.set("provider_id", criteria.provider);
    }

    return await this.get("serviced_offices/showing_contacts", {
      searchParams
    });
  }

  async getServicedOfficeContactById(id) {
    return await this.get("serviced_offices/showing_contacts/" + id);
  }

  async createServicedOfficeContact(data) {
    return await this.post("serviced_offices/showing_contacts", {
      data
    });
  }

  async updateServicedOfficeContact(id, data) {
    return await this.put("serviced_offices/showing_contacts/" + id, {
      data
    });
  }

  async deleteServicedOfficeContact(id) {
    return await this.delete("serviced_offices/showing_contacts/" + id);
  }

  async getServicedOfficeProviders() {
    return await this.get("serviced_offices/providers");
  }

  async createServicedOfficeProvider(data) {
    return await this.post("serviced_offices/providers", {
      data
    });
  }

  async getServicedOfficeProviderById(id) {
    return await this.get("serviced_offices/providers/" + id);
  }

  async updateServicedOfficeProvider(id, data) {
    return await this.put("serviced_offices/providers/" + id, {
      data
    });
  }

  async deleteServicedOfficeProvider(id) {
    return await this.delete("serviced_offices/providers/" + id);
  }

  async getServicedOfficeFloorById(id) {
    return await this.get("serviced_offices/floors/" + id);
  }

  async createServicedOfficeFloor(data) {
    return await this.post("serviced_offices/floors", {
      data
    });
  }

  async updateServicedOfficeFloor(id, data) {
    return await this.put("serviced_offices/floors/" + id, {
      data
    });
  }

  async partialUpdateServicedOfficeFloor(id, data) {
    return await this.patch("serviced_offices/floors/" + id, {
      data
    });
  }

  async deleteServicedOfficeFloor(id) {
    return await this.delete("serviced_offices/floors/" + id);
  }

  async getServicedOfficeLocations() {
    return await this.get("serviced_offices/locations");
  }

  async getSimilarServicedOfficeLocations(locationId) {
    return await this.get(`serviced_offices/locations/${locationId}/similar`);
  }

  async getServicedOfficeLocationById(id) {
    return await this.get("serviced_offices/locations/" + id);
  }

  async getServicedOfficeLocationByUrl(url) {
    return await this.get("serviced_offices/locations/url/" + url);
  }

  async uploadServicedOfficeLocationRooms(id, body) {
    if (body.type !== "text/csv") {
      throw new Error("Set the data type to 'text/csv'");
    }

    const headers = new Headers({
      "Content-Type": "text/csv"
    });
    return await this.patch(`serviced_offices/locations/${id}/update_rooms`, {
      body,
      headers
    });
  }

  async uploadNewServicedOfficeLocationRooms(id, body, jwt) {
    if (body.type !== "text/csv") {
      throw new Error("Set the data type to 'text/csv'");
    }

    const headers = new Headers({
      "Content-Type": "text/csv"
    });
    return await this.post(`serviced_offices/locations/${id}/create_rooms`, {
      body,
      headers,
      jwt
    });
  }

  async getServicedOfficeRoomById(id) {
    return await this.get("serviced_offices/rooms/" + id);
  }

  async getServicedOfficeMemberships() {
    let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return await this.get("serviced_offices/memberships", {
      searchParams: URLSearchParamsFromObj(filters)
    });
  }

  async getAuthServicedOfficeMemberships() {
    let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let jwt = arguments.length > 1 ? arguments[1] : undefined;
    return await this.get("serviced_offices/memberships", {
      searchParams: URLSearchParamsFromObj(filters),
      jwt
    });
  }

  async createServicedOfficeMemberships(data) {
    return await this.post("serviced_offices/memberships", {
      data
    });
  }

  async getServicedOfficeMembershipById(id) {
    return await this.get("serviced_offices/memberships/" + id);
  }

  async createServicedOfficeMembership(data) {
    return await this.post("serviced_offices/memberships", {
      data
    });
  }

  async updateServicedOfficeMembership(id, data) {
    return await this.put("serviced_offices/memberships/" + id, {
      data
    });
  }

  async partialUpdateServicedOfficeMembership(id, data) {
    return await this.patch("shortlists/memberships/" + id, {
      data
    });
  }

  async deleteServicedOfficeMembership(id) {
    return await this.delete("serviced_offices/memberships/" + id);
  }

  async getServicedOfficeTestimonials() {
    let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return await this.get("serviced_offices/testimonials", {
      searchParams: URLSearchParamsFromObj(filters)
    });
  }

  async createServicedOfficeTestimonials(data) {
    return await this.post("serviced_offices/testimonials", {
      data
    });
  }

  async getServicedOfficeTestimonialById(id) {
    return await this.get("serviced_offices/testimonials/" + id);
  }

  async createServicedOfficeTestimonial(data) {
    return await this.post("serviced_offices/testimonials", {
      data
    });
  }

  async updateServicedOfficeTestimonial(id, data) {
    return await this.put("serviced_offices/testimonials/" + id, {
      data
    });
  }

  async partialUpdateServicedOfficeTestimonial(id, data) {
    return await this.patch("shortlists/testimonials/" + id, {
      data
    });
  }

  async deleteServicedOfficeTestimonial(id) {
    return await this.delete("serviced_offices/testimonials/" + id);
  }

  async createProposition(data) {
    return await this.post("shortlists/propositions", {
      data
    });
  }

  async updateProposition(id, data) {
    return await this.put("shortlists/propositions/" + id, {
      data
    });
  }

  async partialUpdateProposition(id, data) {
    return await this.patch("shortlists/propositions/" + id, {
      data
    });
  }

  async deleteProposition(id) {
    return await this.delete("shortlists/propositions/" + id);
  }

  async getPropositionById(id, jwt) {
    return await this.get("shortlists/propositions/" + id, {
      jwt
    });
  }

  async archiveProposition(id) {
    return await this.post(`shortlists/propositions/${id}/archive`, {});
  }

  async getShortlists() {
    let criteria = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const searchParams = new URLSearchParams();

    if (criteria.nameStartsWith) {
      searchParams.set("name", criteria.nameStartsWith);
    }

    if (criteria.company) {
      searchParams.set("company", criteria.company);
    }

    return await this.get("shortlists", {
      searchParams
    });
  }

  async createShortlist(data) {
    return await this.post("shortlists", {
      data
    });
  }

  async updateShortlist(id, data) {
    return await this.put("shortlists/id/" + id, {
      data
    });
  }

  async deleteShortlist(id) {
    return await this.delete("shortlists/id/" + id);
  }

  async getShortlistById(id, jwt) {
    const res = await this.get("shortlists/id/" + id, {
      jwt
    });
    return new Shortlist(res);
  }

  async getShortlistByUrl(url, jwt) {
    const res = await this.get("shortlists/url/" + url, {
      jwt
    });
    return new Shortlist(res);
  }

  async getCurrentShortlist(jwt) {
    return await this.get("shortlists/current", {
      jwt
    });
  }

  async getDealInfoByHubspotId(id) {
    return await this.get("deal_info/hs_deal/" + id);
  }

  async getLockinCompanies() {
    return await this.get("lock_in/companies");
  }

  async getLockinCompanyById(id) {
    return await this.get("lock_in/companies/" + id);
  }

  async createLockinCompany(data) {
    return await this.post("lock_in/companies", {
      data
    });
  }

  async deleteLockinCompany(id) {
    return await this.delete("lock_in/companies/" + id);
  }

  async createLockinContact(data) {
    return await this.post("lock_in/contacts", {
      data
    });
  }

  async deleteLockinContact(id) {
    return await this.delete("lock_in/contacts/" + id);
  }

  async sendLockinMessages(data) {
    return await this.post("lock_in/send_message", {
      data
    });
  }

  async generateLockinMessagesPreview(data) {
    return await this.post("lock_in/preview_message", {
      data
    });
  }

  async getDigitalContracts() {
    return await this.get("digital_contracts");
  }

  async getDigitalContractById(id) {
    return await this.get("digital_contracts/" + id);
  }

  async createDigitalContract(data) {
    return await this.post("digital_contracts", {
      data
    });
  }

  async getSearchSpecifications() {
    return await this.get("search_specifications");
  }

  async getSearchSpecificationById(id) {
    return await this.get("search_specifications/" + id);
  }

  async createSearchSpecification(data) {
    return await this.post("search_specifications", {
      data
    });
  }

  async updateSearchSpecification(id, data) {
    return await this.put("search_specifications/" + id, {
      data
    });
  }

  async getOffers(criteria) {
    let offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    const searchParams = URLSearchParamsFromObj(criteria);

    if (limit) {
      searchParams.set("limit", limit.toString());
    }

    if (offset) {
      searchParams.set("offset", offset.toString());
    }

    const data = await this.get("shortlists/offers", {
      searchParams
    });
    return data;
  }

  async createFormLead(data) {
    return await this.post("form_leads", {
      data
    });
  }

  async updateFormLead(id, data) {
    return await this.patch("form_leads/" + id, {
      data
    });
  }

  async getOwnerEvents(id, range) {
    return await this.get(`/owners/${id}/events`, {
      searchParams: URLSearchParamsFromObj(range)
    });
  }

  async getShowingPrefilledInfo(solutionID) {
    return await this.get("showings/prefilled_info/" + solutionID);
  }

  async createShowing(data) {
    return await this.post("showings", {
      data
    });
  }

  async declineShowingRequest(id) {
    return await this.post(`showings/requests/${id}/decline`, {});
  }

  async getCoworkingSolutionById(id) {
    return await this.get("coworking_marketplace/solutions/" + id);
  }

  async getCoworkingSolutionsByLocationId(locationId) {
    return await this.get("coworking_marketplace/solutions?location_id=" + locationId);
  }

  async getAuthCoworkingSolutionsByLocationId(locationId, jwt) {
    return await this.get("coworking_marketplace/solutions?location_id=" + locationId, {
      jwt
    });
  }

  async getCoworkingSearchResult(criteria) {
    const searchParams = URLSearchParamsFromObj(criteria);
    return await this.get("coworking_marketplace/search", {
      searchParams
    });
  }

  async getCompanies(criteria, jwt) {
    let offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    let limit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    const searchParams = URLSearchParamsFromObj(criteria);

    if (limit) {
      searchParams.set("limit", limit.toString());
    }

    if (offset) {
      searchParams.set("offset", offset.toString());
    }

    const data = await this.get("accounts/companies", {
      searchParams,
      jwt
    });
    return data;
  }

  async getCompanyById(id, jwt) {
    return await this.get("accounts/companies/" + id, {
      jwt
    });
  }

  async createCompany(data, jwt) {
    return await this.post("accounts/companies", {
      data,
      jwt
    });
  }

  async getUsers(criteria, jwt) {
    const searchParams = URLSearchParamsFromObj(criteria);
    const data = await this.get("accounts/users", {
      searchParams,
      jwt
    });
    return data;
  }

  async getUserById(id, jwt) {
    return await this.get("accounts/users/" + id, {
      jwt
    });
  }

  async getCurrentUser(jwt) {
    return await this.get("accounts/users/me", {
      jwt
    });
  }

  async createUser(data, jwt) {
    try {
      return await this.post("accounts/users", {
        data,
        jwt
      });
    } catch (e) {
      const errorText = JSON.parse(e.responseText);
      throw new Error(errorText.email || errorText);
    }
  }

  async createOccuUser(data, jwt) {
    try {
      return await this.post("accounts/users/occu", {
        data,
        jwt
      });
    } catch (e) {
      const errorText = JSON.parse(e.responseText);
      throw new Error(errorText.email || errorText);
    }
  }

  async partialUpdateUser(id, data, jwt) {
    return await this.patch("accounts/users/" + id, {
      data,
      jwt
    });
  }

  async partialUpdateCurrentUser(data, jwt) {
    return await this.patch(`accounts/users/me`, {
      data,
      jwt
    });
  }

  async deleteUser(id, jwt) {
    return await this.delete("accounts/users/" + id, {
      jwt
    });
  }

  async createCustomToken(data, jwt) {
    return await this.post("accounts/impersonation", {
      data,
      jwt
    });
  }

  async requestShowing(data) {
    return await this.post("showings/requests", {
      data
    });
  }

  async getShowingRequests(jwt) {
    return await this.get("showings/requests", {
      jwt
    });
  }

  async saveSolution(id, jwt) {
    return await this.post(`/coworking_marketplace/solutions/${id}/add_to_shortlist`, {
      jwt
    });
  }

  async sendLocationInterest(data) {
    return await this.post(`/coworking_marketplace/interests`, {
      data
    });
  }

  async getGlobalSearch(search, jwt) {
    return await this.get(`/search?q=${search}`, {
      jwt
    });
  }

  async getShortlistShareLink(shortlistUrl, jwt) {
    return await this.post(`/shortlists/url/${shortlistUrl}/share_link`, {
      jwt
    });
  }

  async sendShortlistInvitation(shortlistUrl, data, jwt) {
    return await this.post(`/shortlists/url/${shortlistUrl}/invite`, {
      data,
      jwt
    });
  }

  async getShortlistInvitation(invitationId) {
    return await this.get(`/shortlists/invitations/${invitationId}`);
  }

  async acceptShortlistInvitation(invitationId, jwt) {
    return await this.post(`/shortlists/invitations/${invitationId}/accept`, {
      jwt
    });
  }

  async resetPassword(email, continueUrl, domain) {
    return await this.post("/accounts/users/reset_password", {
      data: {
        email,
        continue_url: continueUrl,
        domain
      }
    });
  }

  async getAreaFeatures(query) {
    return await this.get(`/geo_search/polygon?${query}`);
  }

  async getYtaUrls() {
    return await this.get("/geo_search/urls");
  }

  async getNearbyAreas(city) {
    return await this.get(`geo_search/neighborhoods?city=${city}`);
  }

  async getProviderSubscriptions() {
    return await this.get("serviced_offices/provider_subscriptions");
  }

  async createProviderSubscription(data, jwt) {
    return await this.post("serviced_offices/provider_subscriptions", {
      data,
      jwt
    });
  }

  async updateProviderSubscription(id, data, jwt) {
    return await this.put("serviced_offices/provider_subscriptions/" + id, {
      data,
      jwt
    });
  }

  async deleteProviderSubscription(id, jwt) {
    return await this.delete("serviced_offices/provider_subscriptions/" + id, {
      jwt
    });
  }

  async getProviderSubscriptionById(id) {
    return await this.get("serviced_offices/provider_subscriptions/" + id);
  }

}

const ROOM = "room";
const OWN_SECTION = "own_section";
const ROOM_TYPES = [ROOM, OWN_SECTION];

class RoomListingView {
  constructor(room, location, provider) {
    _defineProperty(this, "room", void 0);

    _defineProperty(this, "loc", void 0);

    _defineProperty(this, "prov", void 0);

    _defineProperty(this, "created", void 0);

    _defineProperty(this, "address", void 0);

    this.room = room;
    this.loc = location;
    this.prov = provider;
    this.created = new Date(room.created);
    this.address = new Address(location.address);
  }
  /**
   * Fields specific to the room view not available through listing
   */


  get hasWindow() {
    return this.room.has_window;
  }

  get isExample() {
    return this.room.is_example;
  }

  get services() {
    return [...this.loc.services];
  }

  get listing() {
    return this.room.listing;
  }

  get provider() {
    return this.prov;
  }

  get location() {
    return this.loc;
  }

  displayExtraServices() {
    return this.loc.extra_services;
  }
  /**
   * Fields common with listings
   */


  get id() {
    return this.room.id;
  }

  get isOverridden() {
    return true;
  }

  get isPublic() {
    return this.room.vacant;
  }

  get isListed() {
    return this.room.vacant || !this.room.vacant && this.room.availability != null;
  }

  get isOffice() {
    return true;
  }

  get isServicedOffice() {
    return true;
  }

  get images() {
    const images = [...this.room.images, ...this.loc.images].map(url => {
      return {
        url,
        tour: false
      };
    });

    if (images.length == 0) {
      return [{
        url: "https://storage.googleapis.com/static-yta-files/default_pic_listing.jpg",
        tour: false
      }];
    }

    return images;
  }

  get floorplans() {
    return [...this.room.floorplans, ...this.loc.floorplans];
  }

  get documents() {
    return [...this.loc.documents, ...this.prov.documents];
  }

  get url() {
    return `${this.room.url}`;
  }

  get metricRent() {
    return this.room.price / this.room.size;
  }

  get monthlyRent() {
    return this.room.price;
  }

  get listMonthlyRent() {
    return this.room.price;
  }

  get bestMonthlyRent() {
    return this.room.official_minimum_price || this.room.price;
  }

  get hasDiscount() {
    return this.room.official_minimum_price && this.room.official_minimum_price < this.room.price;
  }

  get virtualTours() {
    return [];
  }

  get minSize() {
    return this.room.size;
  }

  get maxSize() {
    return this.room.size;
  }

  get maxWorkspaces() {
    return this.room.max_workspaces;
  }

  get minWorkspaces() {
    return Math.floor(this.room.max_workspaces * 0.6);
  }

  get contact() {
    return null;
  }

  get broker() {
    return null;
  }

  get legacyContactData() {
    return null;
  }

  displayAge() {
    /**
     * The age is not relevant for a room, so we just return an empty string as we don't want to display anything
     */
    return "";
  }

  displayAvailability() {
    return this.room.availability ? this.room.availability : "Tillgänglig";
  }

  displayConstructionYear() {
    return "";
  }

  displayDescription() {
    return `${this.room.description}

    ${this.address.toString()}
    ${this.loc.description}

    ${this.prov.name}
    ${this.prov.description}`;
  }

  displayFloor() {
    return "";
  }

  displayNeighbordhood() {
    return "";
  }

  displayParking() {
    return "";
  }

  displayPublicTransport() {
    return "";
  }

  displayEstimatedRent() {
    return `${formatInt(this.monthlyRent)} kr/mån`;
  }

  displayActualRent() {
    return this.displayEstimatedRent();
  }
  /**
   * @deprecated since version 1.6.0. Use displayActualRent or displayEstimatedRent instead.
   */


  displayRent() {
    return this.displayEstimatedRent();
  }

  displayRentDetails() {
    return "";
  }

  displayServicesNearby() {
    return "";
  }

  displayShortDescription() {
    return "";
  }

  displaySize() {
    return `${this.room.size} m²`;
  }

  displaySurroundingsDescription() {
    return "";
  }

  displayTitle() {
    return this.room.title;
  }

  displayType() {
    return "Kontorshotell rum";
  }

  displayWorkspaces() {
    return `${this.minWorkspaces} - ${this.maxWorkspaces} arbetsplatser`;
  }

}

const FIXED_DESK = "fixed_desk";
const FLEX_DESK = "flex_desk";
const ONLY_ACCESS = "only_access";

function displayAddress(address) {
  return `${address.street} ${address.locator},  ${address.city}`;
}

class StatusError$1 extends Error {
  constructor(res, body) {
    super();

    _defineProperty(this, "statusCode", void 0);

    _defineProperty(this, "res", void 0);

    _defineProperty(this, "responseText", void 0);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StatusError$1);
    }

    this.message = `Incorrect statusCode: ${res.status}`;
    this.statusCode = res.status;
    this.res = res;
    this.responseText = body;
  }

  get responseJson() {
    return JSON.parse(this.responseText);
  }

}

const defaultClientOptions$1 = {
  staging: false,
  apiKey: ""
};

class YtaApiClient$1 {
  constructor(options) {
    _defineProperty(this, "opts", void 0);

    this.opts = { ...defaultClientOptions$1,
      ...options
    };
  }

  get origin() {
    return this.opts.staging ? "https://staging-api.yta.se" : "https://api.yta.se";
  }

  async request(path, method, options) {
    const url = new URL(path, this.origin);
    const searchParams = options.searchParams || new URLSearchParams();

    for (const [key, value] of searchParams) {
      url.searchParams.append(key, value);
    }

    const headers = options.headers || new Headers(); // Authenticate all the requests if we have an JWT or API key.

    if (options.jwt || this.opts.apiKey) {
      headers.set("Authorization", `Bearer ${options.jwt || this.opts.apiKey}`);
    }

    let body = null;
    if ("body" in options) body = options.body;

    if (!body && "data" in options) {
      body = JSON.stringify(options.data);
      headers.set("Content-Type", "application/json");
    }

    let credentials = undefined;
    if ("credentials" in options) credentials = options.credentials;
    const validStatuses = new Set(options.validStatuses || [200, 201, 202, 204]);
    const init = {
      method,
      headers,
      body
    };

    if (credentials) {
      init.credentials = credentials;
    }

    const response = await fetch(url.toString(), init);

    if (!validStatuses.has(response.status)) {
      const responseBody = await response.text();
      throw new StatusError$1(response, responseBody);
    }

    return response;
  }

  async get(path) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const resp = await this.request(path, "GET", options);
    return await resp.json();
  }

  async post(path, options) {
    const resp = await this.request(path, "POST", options);
    return await resp.json();
  }

  async put(path, options) {
    const resp = await this.request(path, "PUT", options);
    return await resp.json();
  }

  async patch(path, options) {
    const resp = await this.request(path, "PATCH", options);
    return await resp.json();
  }

  async delete(path) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    await this.request(path, "DELETE", options);
  }

}

const YtaClientContext = createContext(new YtaApiClient$1({}));
const YtaClientProvider = YtaClientContext.Provider;

const useYtaClient = () => useContext(YtaClientContext);

function useYtaQuery(queryObj) {
  const client = useYtaClient();
  return useQuery({ ...queryObj,
    queryFn: async () => await queryObj.queryFn(client)
  });
}
function useAuthQuery(queryObj) {
  const user = useUser();
  const client = useYtaClient();
  return useQuery({ ...queryObj,
    queryFn: async () => {
      // User would be `null` only if the user disconnected precisely at the same time as a request was going to fire
      // This is a low probability enough that we consider that unlikely
      const token = await user.data.getIdToken();
      return await queryObj.queryFn(client, token);
    },
    enabled: !!user.data && queryObj.enabled !== false
  });
}
function useYtaMutation(mutationFn, mutationOpts) {
  const client = useYtaClient();
  const queryClient = useQueryClient();

  const finalMutation = async data => await mutationFn.fn(client, data);

  return useMutation(finalMutation, { ...mutationOpts,
    onSuccess: (data, variables, context) => {
      if (mutationFn.getInvalidateQueries) {
        const invalidateQueries = mutationFn.getInvalidateQueries(data);

        for (const invalidateQuery of invalidateQueries) {
          void queryClient.invalidateQueries(invalidateQuery);
        }
      }

      if (mutationOpts.onSuccess) {
        void mutationOpts.onSuccess(data, variables, context);
      }
    }
  });
}
function useAuthYtaMutation(mutationFn, mutationOpts) {
  const client = useYtaClient();
  const queryClient = useQueryClient();
  const user = useUser();

  const finalMutation = async data => {
    if (!user.data) {
      throw "Action only possible for logged in users";
    }

    const token = await user.data.getIdToken();
    return await mutationFn.fn(client, token, data);
  };

  return useMutation(finalMutation, { ...mutationOpts,
    onSuccess: (data, variables, context) => {
      if (mutationFn.getInvalidateQueries) {
        const invalidateQueries = mutationFn.getInvalidateQueries(data);

        for (const invalidateQuery of invalidateQueries) {
          void queryClient.invalidateQueries(invalidateQuery);
        }
      }

      if (mutationOpts.onSuccess) {
        void mutationOpts.onSuccess(data, variables, context);
      }
    }
  });
}

function buildApiQuery(arg, options) {
  return function () {
    const queryObj = typeof arg === "function" ? arg(...arguments) : arg;
    let finalQueryFn;

    if ("path" in queryObj) {
      const path = queryObj.path;

      finalQueryFn = async function (client) {
        return await client.get(path, options);
      };
    } else {
      finalQueryFn = async client => {
        return await queryObj.queryFn(client);
      };
    }

    return { ...queryObj,
      queryFn: finalQueryFn
    };
  };
}
function buildAuthenticatedApiQuery(arg, options) {
  return function () {
    const queryObj = typeof arg === "function" ? arg(...arguments) : arg;
    let finalQueryFn;

    if ("path" in queryObj) {
      const path = queryObj.path;

      finalQueryFn = async function (client, token) {
        return await client.get(path, { ...options,
          jwt: token
        });
      };
    } else {
      finalQueryFn = async (client, token) => {
        return await queryObj.queryFn(client, token);
      };
    }

    return { ...queryObj,
      queryFn: finalQueryFn
    };
  };
}

const occuProvider = buildAuthenticatedApiQuery({
  path: `occu/providers`,
  queryKey: ["providers"],
  staleTime: 60 * 60 * 1000
});
const providerById = buildApiQuery(_ref => {
  let {
    id
  } = _ref;
  return {
    path: `serviced_offices/providers/${id}`,
    queryKey: ["providers", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!id
  };
});
const occuLocations = buildAuthenticatedApiQuery({
  path: `occu/locations`,
  queryKey: ["locations"],
  staleTime: 60 * 60 * 1000
});
const allLocations = buildAuthenticatedApiQuery({
  path: `serviced_offices/locations`,
  queryKey: ["locations"],
  staleTime: 60 * 60 * 1000
});
const allLocationsByCountry = buildAuthenticatedApiQuery(_ref2 => {
  let {
    country
  } = _ref2;
  return {
    path: `serviced_offices/locations?country=${country}`,
    queryKey: ["locations", {
      country
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!country
  };
});
const allProviders = buildApiQuery({
  path: "serviced_offices/providers",
  queryKey: ["providers"],
  staleTime: 60 * 60 * 1000,
  onSuccess: data => data.sort((providerA, providerB) => {
    const a = providerA.name.toLowerCase();
    const b = providerB.name.toLowerCase();

    if (b < a) {
      return 1;
    } else if (b > a) {
      return -1;
    }

    return 0;
  })
});
const occuLocationById = buildAuthenticatedApiQuery(_ref3 => {
  let {
    id
  } = _ref3;
  return {
    path: `occu/locations/${id}`,
    queryKey: ["locations", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!id
  };
});
const locationById = buildAuthenticatedApiQuery(_ref4 => {
  let {
    id,
    queryClient
  } = _ref4;
  return {
    path: `serviced_offices/locations/${id}`,
    queryKey: ["locations", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    initialData: () => {
      var _queryClient$getQuery;

      return (_queryClient$getQuery = queryClient.getQueryData(["locations"], {
        exact: true
      })) === null || _queryClient$getQuery === void 0 ? void 0 : _queryClient$getQuery.find(l => l.id === id);
    },
    enabled: !!id
  };
});
const floorById = buildApiQuery(_ref5 => {
  let {
    id
  } = _ref5;
  return {
    path: `serviced_offices/floors/${id}`,
    queryKey: ["floors", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!id
  };
});
const allRooms = buildAuthenticatedApiQuery({
  path: "serviced_offices/rooms",
  queryKey: ["rooms"],
  staleTime: 60 * 60 * 10000
});

const roomsByLocationRequest = _ref6 => {
  let {
    location,
    queryClient
  } = _ref6;
  return {
    path: `serviced_offices/rooms?location=${location}`,
    queryKey: ["rooms", {
      location
    }],
    staleTime: 60 * 60 * 1000,
    initialData: () => {
      var _queryClient$getQuery2;

      return (_queryClient$getQuery2 = queryClient.getQueryData(["rooms"], {
        exact: true
      })) === null || _queryClient$getQuery2 === void 0 ? void 0 : _queryClient$getQuery2.filter(r => r.location === location);
    },
    enabled: !!location
  };
};

const roomsRequestOptions = {
  headers: new Headers({
    "Cache-Control": "no-cache"
  })
};
const roomsByLocation = buildApiQuery(roomsByLocationRequest, roomsRequestOptions);
const roomsByLocationPrivateFields = buildAuthenticatedApiQuery(roomsByLocationRequest, roomsRequestOptions);

const roomByIdRequest = _ref7 => {
  let {
    id,
    queryClient
  } = _ref7;
  return {
    path: `serviced_offices/rooms/${id}`,
    queryKey: ["rooms", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    initialData: () => {
      var _queryClient$getQuery3;

      return (_queryClient$getQuery3 = queryClient.getQueryData(["rooms"], {
        exact: true
      })) === null || _queryClient$getQuery3 === void 0 ? void 0 : _queryClient$getQuery3.find(r => r.id == id);
    },
    enabled: !!id
  };
};

const roomById = buildApiQuery(roomByIdRequest);
const roomByIdPrivateFields = buildAuthenticatedApiQuery(roomByIdRequest);
const roomByUrl = buildApiQuery(_ref8 => {
  let {
    url,
    queryClient
  } = _ref8;
  return {
    path: `serviced_offices/rooms/url/${url}`,
    queryKey: ["rooms", {
      url
    }],
    staleTime: 60 * 60 * 1000,
    initialData: () => {
      var _queryClient$getQuery4;

      return (_queryClient$getQuery4 = queryClient.getQueryData(["rooms"], {
        exact: true
      })) === null || _queryClient$getQuery4 === void 0 ? void 0 : _queryClient$getQuery4.find(r => r.url == url);
    },
    enabled: !!url
  };
});
const roomsCsvByLocation = buildAuthenticatedApiQuery(_ref9 => {
  let {
    location
  } = _ref9;
  return {
    queryFn: async (client, token) => {
      const response = await client.request(`/serviced_offices/locations/${location}/download_rooms`, "GET", {
        jwt: token
      });
      return response.blob();
    },
    queryKey: ["rooms", "csv", {
      location
    }]
  };
});
const services = buildApiQuery({
  path: "serviced_offices/services",
  queryKey: ["coworking_services"],
  staleTime: 60 * 60 * 1000
});
const floorUpToDateRequestOptions = {
  headers: new Headers({
    "Cache-Control": "no-cache"
  })
};
const floorUpToDate = buildAuthenticatedApiQuery(_ref10 => {
  let {
    id
  } = _ref10;
  return {
    path: `serviced_offices/floors/${id}/up_to_date`,
    queryKey: ["rooms", "up_to_date", {
      floor: id
    }]
  };
}, floorUpToDateRequestOptions);
const locationUpToDate = buildAuthenticatedApiQuery(_ref11 => {
  let {
    id
  } = _ref11;
  return {
    path: `serviced_offices/locations/${id}/up_to_date`,
    queryKey: ["rooms", "up_to_date", {
      location: id
    }]
  };
});

async function getRoom(location, client, token) {
  return await client.get(`serviced_offices/rooms?location=${location}`, {
    jwt: token
  });
}

const roomsParallel = buildAuthenticatedApiQuery(_ref12 => {
  let {
    locationIds
  } = _ref12;
  return {
    queryFn: async (client, token) => {
      const result = await Promise.all(locationIds ? locationIds === null || locationIds === void 0 ? void 0 : locationIds.map(location => getRoom(location, client, token)) : []);
      return result.flat(1);
    },
    queryKey: ["rooms", {
      locationIds
    }],
    staleTime: 60 * 60 * 1000,
    enabled: locationIds !== undefined && locationIds.length > 0
  };
});

async function getFloor(floor, client) {
  return await client.get(`serviced_offices/floors/${floor}`);
}

const floorsParallelArgs = _ref13 => {
  let {
    floorIds
  } = _ref13;
  return {
    queryFn: async client => {
      const result = await Promise.all(floorIds.map(floorId => getFloor(floorId, client)));
      return result.flat(1);
    },
    queryKey: ["floors", {
      floorIds
    }],
    staleTime: 60 * 60 * 1000,
    enabled: floorIds.length > 0
  };
};

const floorsParallel = buildApiQuery(floorsParallelArgs);
const showingContactsByProvider = buildAuthenticatedApiQuery(_ref14 => {
  let {
    provider
  } = _ref14;
  return {
    path: `serviced_offices/showing_contacts?provider_id=${provider}`,
    queryKey: ["showing_contacts", {
      provider
    }],
    enabled: !!provider
  };
});
const showingContactsByLocation = buildAuthenticatedApiQuery(_ref15 => {
  let {
    location
  } = _ref15;
  return {
    path: `serviced_offices/showing_contacts?location_id=${location}`,
    queryKey: ["showing_contacts", {
      location
    }],
    enabled: !!location
  };
});
const conferenceRoomsByLocation = buildApiQuery(_ref16 => {
  let {
    location
  } = _ref16;
  return {
    path: `serviced_offices/conference_rooms?location=${location}`,
    queryKey: ["conference_rooms", {
      location
    }],
    enabled: !!location,
    select: data => data.sort((a, b) => a.name.localeCompare(b.name))
  };
});
const providerSubscriptions = buildApiQuery({
  path: `serviced_offices/provider_subscriptions`,
  queryKey: ["subscriptions"],
  staleTime: 60 * 60 * 1000
});

const currentUserData = buildAuthenticatedApiQuery({
  path: "accounts/users/me",
  queryKey: ["currentUserData"],
  staleTime: 60 * 60 * 1000
});
const usersByCompany = buildAuthenticatedApiQuery(_ref => {
  let {
    company
  } = _ref;
  return {
    path: `accounts/users?company=${company}`,
    queryKey: ["users", {
      company
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!company
  };
});

const propositionById = buildApiQuery(_ref => {
  let {
    id
  } = _ref;
  return {
    path: `shortlists/propositions/${id}`,
    queryKey: ["propositions", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!id
  };
});

const showingRequestById = buildApiQuery(_ref => {
  let {
    id
  } = _ref;
  return {
    path: `showings/requests/${id}`,
    queryKey: ["showings", "request", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!id
  };
});

const createLocation = {
  fn: async (client, token, data) => client.post("/serviced_offices/locations", {
    data,
    jwt: token
  })
};
const updateLocation = {
  fn: async (client, token, data) => client.put(`/serviced_offices/locations/${data.id}`, {
    data,
    jwt: token
  }),
  getInvalidateQueries: data => [["locations", {
    id: data.id
  }]]
};
const partialUpdateLocation = {
  fn: async (client, token, data) => client.patch(`/serviced_offices/locations/${data.id}`, {
    data,
    jwt: token
  }),
  getInvalidateQueries: data => [["locations", {
    id: data.id
  }]]
};
const deleteLocation = {
  fn: async (client, token, _ref) => {
    let {
      id
    } = _ref;
    return client.delete(`/serviced_offices/locations/${id}`, {
      jwt: token
    });
  },
  getInvalidateQueries: () => [["locations"], ["providers"], ["rooms"]]
};
const deleteContact = {
  fn: async (client, _ref2) => {
    let {
      id
    } = _ref2;
    return client.delete(`/serviced_offices/showing_contacts/${id}`);
  },
  getInvalidateQueries: () => [["showing_contacts"]]
};
const deleteRoom = {
  fn: async (client, token, _ref3) => {
    let {
      id
    } = _ref3;
    return client.delete(`/serviced_offices/rooms/${id}`, {
      jwt: token
    });
  },
  getInvalidateQueries: () => [["rooms"]]
};
const partialUpdateRoom = {
  fn: async (client, token, _ref4) => {
    let {
      id,
      ...data
    } = _ref4;
    return client.patch(`/serviced_offices/rooms/${id}`, {
      data,
      jwt: token
    });
  },
  getInvalidateQueries: () => [["rooms"]]
};
const createRoom = {
  fn: async (client, token, data) => client.post("/serviced_offices/rooms", {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["rooms"]]
};
const markFloorUpToDate = {
  fn: async (client, token, _ref5) => {
    let {
      id
    } = _ref5;
    return client.put(`/serviced_offices/floors/${id}/up_to_date`, {
      data: {
        up_to_date: true
      },
      jwt: token
    });
  },
  getInvalidateQueries: () => [["rooms", "up_to_date"]]
};
const markLocationUpToDate = {
  fn: async (client, token, _ref6) => {
    let {
      id
    } = _ref6;
    return client.put(`/serviced_offices/floors/${id}/up_to_date`, {
      data: {
        up_to_date: true
      },
      jwt: token
    });
  },
  getInvalidateQueries: () => [["rooms", "up_to_date"]]
};
const roomCSVUpdate = {
  fn: async (client, token, _ref7) => {
    let {
      location,
      csv
    } = _ref7;
    return client.patch(`/serviced_offices/locations/${location}/update_rooms`, {
      jwt: token,
      body: csv,
      headers: new Headers({
        "Content-Type": "text/csv"
      })
    });
  },
  getInvalidateQueries: () => [["rooms"]]
};
const createShowingContact = {
  fn: async (client, token, data) => client.post("/serviced_offices/showing_contacts", {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["showing_contacts"]]
};
const deleteConferenceRoom = {
  fn: async (client, token, _ref8) => {
    let {
      id
    } = _ref8;
    return client.delete(`/serviced_offices/conference_rooms/${id}`, {
      jwt: token
    });
  },
  getInvalidateQueries: () => [["conference_rooms"]]
};
const partialUpdateConferenceRoom = {
  fn: async (client, token, _ref9) => {
    let {
      id,
      ...data
    } = _ref9;
    return client.patch(`/serviced_offices/conference_rooms/${id}`, {
      data,
      jwt: token
    });
  },
  getInvalidateQueries: () => [["conference_rooms"]]
};
const createConferenceRoom = {
  fn: async (client, token, data) => client.post("serviced_offices/conference_rooms", {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["conference_rooms"]]
};
const partialUpdateProvider = {
  fn: async (client, token, data) => client.patch(`/serviced_offices/providers/${data.id}`, {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["providers"]]
};
const createConferenceRoomRequest = {
  fn: async (client, _ref10) => {
    let {
      data
    } = _ref10;
    return client.post(`/coworking_marketplace/conference_room_requests`, {
      data
    });
  }
};

const createShowing = {
  fn: async (client, data) => client.post("/showings", {
    data
  })
};
const createShowingRequest = {
  fn: async (client, token, data) => client.post("showings/requests", {
    data,
    jwt: token
  })
};
const acceptShowingRequest = {
  fn: async (client, _ref) => {
    let {
      id
    } = _ref;
    return client.post(`showings/requests/${id}/accept`, {});
  }
};
const declineShowingRequest = {
  fn: async (client, _ref2) => {
    let {
      id
    } = _ref2;
    return client.post(`showings/requests/${id}/decline`, {});
  }
};

const createComment = {
  fn: (client, token, data) => client.post("/occu/comments", {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["occu_comments"]]
};
const updateComment = {
  fn: (client, token, _ref) => {
    let {
      id,
      content
    } = _ref;
    return client.patch(`/occu/comments/${id}`, {
      data: {
        content
      },
      jwt: token
    });
  },
  getInvalidateQueries: () => [["occu_comments"]]
};
const deleteComment = {
  fn: (client, token, _ref2) => {
    let {
      id
    } = _ref2;
    return client.delete(`/occu/comments/${id}`, {
      jwt: token
    });
  },
  getInvalidateQueries: () => [["occu_comments"]]
};

const resetPassword = {
  fn: async (client, _ref) => {
    let {
      email,
      continueUrl,
      domain
    } = _ref;
    return client.post("/accounts/users/reset_password", {
      data: {
        email,
        continue_url: continueUrl,
        domain
      }
    });
  }
};

const commentsForRoom = buildAuthenticatedApiQuery(_ref => {
  let {
    id
  } = _ref;
  return {
    path: `/occu/comments?room=${id}`,
    queryKey: ["occu_comments", {
      room: id
    }]
  };
});

const membershipRequestOptions = {
  headers: new Headers({
    "Cache-Control": "no-store"
  })
};
const membershipById = buildApiQuery(_ref => {
  let {
    id
  } = _ref;
  return {
    path: `serviced_offices/memberships/${id}`,
    queryKey: ["memberships", {
      id
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!id
  };
}, membershipRequestOptions);
const memberships = buildApiQuery(_ref2 => {
  let {
    locationId
  } = _ref2;
  return {
    path: locationId ? `serviced_offices/memberships?location=${locationId}` : "serviced_offices/memberships",
    queryKey: ["memberships", {
      locationId
    }],
    staleTime: 60 * 60 * 1000
  };
}, membershipRequestOptions);
const authMemberships = buildAuthenticatedApiQuery(_ref3 => {
  let {
    locationId
  } = _ref3;
  return {
    path: locationId ? `serviced_offices/memberships?location=${locationId}` : "serviced_offices/memberships",
    queryKey: ["memberships", "auth", {
      locationId
    }],
    staleTime: 60 * 60 * 1000
  };
}, membershipRequestOptions);

const updateMembership = {
  fn: async (client, token, data) => client.put(`serviced_offices/memberships/${data.id}`, {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["memberships"]]
};
const createMembership = {
  fn: async (client, token, data) => client.post("serviced_offices/memberships", {
    data,
    jwt: token
  }),
  getInvalidateQueries: () => [["memberships"]]
};
const deleteMembership = {
  fn: async (client, token, _ref) => {
    let {
      id
    } = _ref;
    return client.delete(`serviced_offices/memberships/${id}`, {
      jwt: token
    });
  },
  getInvalidateQueries: () => [["memberships"]]
};

const geoSearch = {
  fn: async (client, _ref) => {
    let {
      search,
      country
    } = _ref;
    return client.get(`/geo_search/autocomp?q=${search}&country=${country}`);
  }
};

const areaFeatures = buildApiQuery(_ref => {
  let {
    ids,
    area_url
  } = _ref;
  const params = area_url ? new URLSearchParams([["area_url", area_url]]) : ids.reduce((params, area) => {
    params.append("area", area);
    return params;
  }, new URLSearchParams());
  return {
    path: `geo_search/polygon?${params.toString()}`,
    queryKey: ["areaFeatures", {
      ids,
      area_url
    }],
    staleTime: 5 * 60 * 1000,
    enabled: ids.length > 0 || area_url != undefined
  };
});
const nearbyAreas = buildApiQuery(_ref2 => {
  let {
    city
  } = _ref2;
  return {
    path: `geo_search/neighborhoods?city=${city || ""}`,
    queryKey: ["nearbyAreas", {
      city
    }],
    staleTime: 60 * 60 * 1000,
    enabled: !!city
  };
});
const ytaUrls = buildApiQuery(() => ({
  path: "geo_search/urls",
  queryKey: ["ytaUrls"],
  staleTime: 60 * 60 * 1000
}));

const postFileToLocation = {
  fn: async (client, _ref) => {
    let {
      location,
      type,
      file
    } = _ref;
    return client.post(location, {
      headers: new Headers({
        "content-type": type
      }),
      credentials: "same-origin",
      body: file
    });
  }
};

const postFileToLocation$1 = (location, type, file, originForAPI) => {
  return fetch(`${originForAPI}${location}`, {
    method: "POST",
    headers: {
      "Content-Type": type
    },
    credentials: "same-origin",
    body: file
  });
};

const requestUpload = (data, originForAPI) => {
  return fetch(`${originForAPI}/file_upload/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "same-origin",
    body: JSON.stringify(data)
  });
};

function calculateMD5(file) {
  /*
  Uses https://github.com/satazor/js-spark-md5 to calculate
  a MD5 hash of the file object incrementally. This is very close
  to the github example and seems to work perfectly.
  */
  return new Promise(function (resolve, reject) {
    const chunkSize = 1048576,
          chunks = Math.ceil(file.size / chunkSize),
          spark = new SparkMD5.ArrayBuffer(),
          fileReader = new FileReader();
    let currentChunk = 0;

    function loadNext() {
      const start = currentChunk * chunkSize,
            end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(file.slice(start, end));
    }

    fileReader.onload = function (e) {
      if (!e.target || !e.target.result) {
        throw new Error();
      }

      spark.append(e.target.result);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    fileReader.onerror = function () {
      reject("Impossible to calculate the MD5");
    };

    loadNext();
  });
}

async function uploadFile(file, kind, group, originForAPI) {
  const md5 = await calculateMD5(file);
  const requestResponse = await requestUpload({
    kind: kind,
    group: group,
    filename: file.name,
    md5
  }, originForAPI);

  if (!requestResponse.ok) {
    throw new Error(`Failed to request upload for ${file.name} (${requestResponse.status})`);
  }

  const uploadURL = requestResponse.headers.get("location");

  if (!uploadURL) {
    throw new Error(`Failed to get upload url for the file ${file.name}`);
  }

  const uploadResponse = await postFileToLocation$1(uploadURL, file.type, file, originForAPI);

  if (!uploadResponse.ok) {
    throw new Error(`Failed to upload the file ${file.name} (${uploadResponse.status})`);
  }

  const content = await uploadResponse.json();
  return content.uri;
}

export { Address, CountryCode, CountryName, FIXED_DESK, FLEX_DESK, ONLY_ACCESS, OWN_SECTION, Proposition, ROOM, ROOM_TYPES, RoomListingView, Shortlist, StatusError, YtaApiClient, YtaApiClient$1 as YtaApiClientV2, YtaClientContext, YtaClientProvider, acceptShowingRequest, allLocations, allLocationsByCountry, allProviders, allRooms, areaFeatures, authMemberships, buildApiQuery, buildAuthenticatedApiQuery, calculateMD5, commentsForRoom, conferenceRoomsByLocation, createComment, createConferenceRoom, createConferenceRoomRequest, createLocation, createMembership, createRoom, createShowing, createShowingContact, createShowingRequest, currentUserData, declineShowingRequest, deleteComment, deleteConferenceRoom, deleteContact, deleteLocation, deleteMembership, deleteRoom, displayAddress, floorById, floorUpToDate, floorsParallel, formatDate, formatDec, formatInt, formatTime, geoSearch, locationById, locationUpToDate, markFloorUpToDate, markLocationUpToDate, membershipById, memberships, nearbyAreas, occuLocationById, occuLocations, occuProvider, partialUpdateConferenceRoom, partialUpdateLocation, partialUpdateProvider, partialUpdateRoom, postFileToLocation, propositionById, providerById, providerSubscriptions, requestUpload, resetPassword, roomById, roomByIdPrivateFields, roomByUrl, roomCSVUpdate, roomsByLocation, roomsByLocationPrivateFields, roomsCsvByLocation, roomsParallel, roomsToPropositionPayload, services, showingContactsByLocation, showingContactsByProvider, showingRequestById, updateComment, updateLocation, updateMembership, uploadFile, useAuthQuery, useAuthYtaMutation, useYtaMutation, useYtaQuery, usersByCompany, ytaUrls };
